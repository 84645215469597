<template>
  <div class="examlist-layout">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${$route.query.paperBankId}` }">试卷列表</el-breadcrumb-item>
        <el-breadcrumb-item>考试列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 100%; padding: 15px;">
      <div class="bg-shadow func-bar">
        <div class="batchDele">
          <!--          <Button class="add-btn" type="primary" @click="handleRel">-->
          <!--            <svg class="icon" aria-hidden="true">-->
          <!--              <use xlink:href="#icon-fabu" />-->
          <!--            </svg>-->
          <!--            继续发布-->
          <!--          </Button>-->
          <Button @click="checkLength" class="add-btn" type="primary">
            <svg class="icon spread" aria-hidden="true">
              <use xlink:href="#icon-shanchu" />
            </svg>
            删除
          </Button>
        </div>
        <div class="search-container">
          <Input class="searchInput" v-model="searchContent" search placeholder="请输入考试名称" />
        </div>
      </div>
      <div class="table-wrapper">
        <Table :columns="columns" :data="tableData" :loading="loading" @on-row-click="examReport" @on-selection-change="selectedChange">
          <template slot-scope="{ row, index }" slot="name">
            <div class="nowrap" :title="row.name">{{ row.name }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="description">
            <div :title="row.description">{{ row.description }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="limitTime">
            <div>{{ row.limitTime }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="condition">
            <div>{{ row.condition }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="createdTime">
            <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd') }}</span>
          </template>
          <template slot-scope="{ row, index }" slot="creatorName">
            <div class="nowrap" :title="row.creatorName">
              {{ row.creatorName }}
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="pt">
            <div class="row-action-container" style="white-space: nowrap;">
              <i title="下载报告" style="cursor: pointer;font-size: 18px" class="el-icon-download" @click.stop="downloadExamReport(row)" />
              <i title="导入报告" style="cursor: pointer;font-size: 18px" class="el-icon-upload2" @click.stop="exportExamFile(row)" />
              <i title="考试报告" style="cursor: pointer;font-size: 18px" class="el-icon-document" @click.stop="examReport(row)" />
              <Icon
                title="删除"
                aria-hidden="true"
                style="cursor: pointer;font-size: 20px"
                type="ios-trash-outline"
                @click.stop="handleClickMenu('delete', row)"
              />
              <Icon
                title="修改"
                aria-hidden="true"
                style="cursor: pointer; font-size: 20px"
                type="ios-create-outline"
                @click.stop="
                  paperBank = row
                  modifyPaperBankModal = true
                "
              />
            </div>
          </template>
        </Table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          show-total
          @on-change="changePage"
          show-sizer
          @on-page-size-change="changeSize"
        />
      </div>
    </div>
    <RevisionModal :is-add="false" v-model="modifyPaperBankModal" :paper-bank="paperBank" @on-success="handleOperationSuccess" />
    <DeleteModal v-model="deleteVisible" title="确认删除考试吗？" :loading="deleteLoading" @on-ok="deleteExam()">
      <span>删除考试后不可恢复，你还要继续吗？</span>
    </DeleteModal>
    <el-dialog :visible="showRel" width="500px" @close="cancelRel" title="发布考试" center class="add-modal">
      <Form :model="relForm" ref="relForm" :label-width="100" :rules="rules">
        <FormItem label="考试名称" prop="name">
          <Input v-model="relForm.name" placeholder="请输入考试名称" />
        </FormItem>
        <FormItem label="考试简介" prop="description">
          <Input v-model="relForm.description" placeholder="请输入考试简介" />
        </FormItem>
        <FormItem label="考试时长(分钟)" prop="limitTime">
          <InputNumber v-model="relForm.limitTime" placeholder="请输入考试时长(分钟)" :min="1" :max="999" style="width: 100%" />
        </FormItem>
        <FormItem label="通过比例(0~1)" prop="passRate">
          <InputNumber v-model="relForm.passRate" placeholder="请输入考试通过分数" :min="0" :max="1" :step="0.1" style="width: 100%" />
        </FormItem>
        <FormItem label="开始时间">
          <el-date-picker
            v-model="relForm.startTime"
            type="datetime"
            size="small"
            style="width: 200px"
            :picker-options="{ disabledDate: checkStartDate }"
            @change="checkStartTime"
            placeholder="选择日期和时间"
          >
          </el-date-picker>
        </FormItem>
        <FormItem label="结束时间">
          <el-date-picker
            v-model="relForm.endTime"
            type="datetime"
            size="small"
            style="width: 200px"
            :picker-options="{ disabledDate: checkStartDate }"
            @change="checkStartDate(relForm.endTime)"
            @blur="checkStartDate(relForm.endTime)"
            placeholder="选择日期和时间"
          >
          </el-date-picker>
        </FormItem>
      </Form>
      <div class="footer" style="text-align: center">
        <Button @click="cancelRel" class="modal-btn" style="margin-right: 10px">取消</Button>
        <Button type="primary" class="modal-btn" :loading="isConfirm" @click="confirmRel">发布</Button>
      </div>
    </el-dialog>
    <el-dialog class="uploadDialog" :visible.sync="IsImportExam" width="45%" title="上传总体考试报告">
      <p style="margin-bottom: 15px;">文件</p>
      <el-upload class="upload-demo" drag action="" :before-upload="uploadFile">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <Tag v-if="file" style="width: 192px" type="dot" closable color="primary" @on-close="file = null">{{ file.name }}</Tag>
      <div class="footer" style="display: flex;justify-content: center;margin: 15px 0;">
        <el-button size="mini" @click="IsImportExam = false">取消</el-button>
        <el-button size="mini" :disabled="!canAble" type="primary" @click="uploadSerFile(url)">确定</el-button>
      </div>
    </el-dialog>
    <StaffSelectDialog ref="StaffSelect" style="min-width: 1080px"></StaffSelectDialog>
  </div>
</template>

<script>
import { getStatusByValue, EXAM_STATUS } from '@/util/examStatus'
import examApi from '@api/exam'
import paperAnswerDetail from '../paper/paperAnswerDetail'
import RevisionModal from '../paper/RevisionModal'
import DeleteModal from '../../../common/DeleteModal'
import { throttle } from '@util/throttle'
import exam from '../../../../api/exam'
import oss from '../../../../api/oss'
import fileApi from '../../../../api/file'
import StaffSelectDialog from '@/components/common/dialog/StaffSelectDialog'

export default {
  components: { DeleteModal, paperAnswerDetail, RevisionModal, StaffSelectDialog },
  name: 'IndividualPapers',
  data() {
    return {
      canAble: false,
      searchContent: '',
      url: '',
      file: null,
      showRel: false,
      selectedExamId: [],
      loading: false,
      showAllocateModal: false,
      deleteVisible: false,
      deleteLoading: false,
      selectedExam: {},
      isSpread: false,
      IsImportExam: false,
      paperId: 0,
      paperBank: {
        type: Object,
        required: true
      },
      modifyPaperBankModal: false,
      relForm: {
        courseId: 1,
        description: '',
        endTime: '',
        limitTime: 100,
        messageAfterExam: '',
        messageBeforeExam: '',
        name: '',
        paperId: 0,
        passRate: 0.6,
        repeated: 1,
        startTime: '',
        passScore: 0,
        studentIds: [],
        type: 1
      },
      searchObj: {
        examName: '',
        examPaperName: '',
        status: ''
      },
      tableData: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '考试名称',
          slot: 'name',
          className: 'name-column',
          width: 150,
          tooltip: true
        },
        {
          title: '考试简介',
          align: 'center',
          slot: 'description'
        },
        {
          title: '考试时长(分钟)',
          slot: 'limitTime',
          align: 'center',
          sortable: true
        },
        {
          title: '考试通过比例(0-1)',
          align: 'center',
          slot: 'condition'
        },
        {
          title: '发布时间',
          align: 'center',
          slot: 'createdTime',
          sortable: true
        },
        {
          title: '发布人',
          align: 'center',
          key: 'creatorName',
          slot: 'creatorName',
          className: 'count-column',
          sortable: true
        },
        {
          title: '操作',
          slot: 'pt',
          width: 200,
          className: 'opt-column'
        }
      ],
      page: 0,
      size: 10,
      total: 0,
      refresh: false, // 是否批量删除(true 否)
      isConfirm: false,
      rules: {
        name: [
          { required: true, message: '请设置考试名称', trigger: 'blur' },
          { max: 30, message: '考试名名称不得超过30个字', trigger: 'blur' }
        ],
        description: {
          max: 30,
          message: '考试名简介不得超过30个字',
          trigger: 'blur'
        },
        limitTime: [
          {
            type: 'number',
            required: true,
            message: '请设置考试时长',
            trigger: 'blur'
          },
          {
            type: 'number',
            max: 999,
            message: '已达考试时长上限',
            trigger: 'blur'
          }
        ],
        passRate: {
          type: 'number',
          required: true,
          message: '请设置通过比例',
          trigger: 'blur'
        }
      }
    }
  },
  watch: {
    IsImportExam(val) {
      if (val === false) {
        this.canAble = false
      }
    }
  },
  mounted() {
    this.paperId = this.$route.query['id']
  },
  created() {
    this.searchData(true)
    this.$watch(
      'searchContent',
      throttle(newQuery => {
        this.$emit('searchContent', newQuery)
        this.searchData(true)
      })
    )
  },
  computed: {
    EXAM_STATUS() {
      return EXAM_STATUS
    }
  },
  methods: {
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    uploadFile(file) {
      let _this = this
      this.file = file
      if (window.uploadUrl) {
        // 局域网
        let formData = new FormData()
        formData.append('file', file)
        fileApi.uploadServeFile(formData).then(res => {
          if (res.code === 0) {
            this.url = res.res
            this.canAble = true
          } else {
          }
        })
      } else {
        oss.upType().then(res => {
          if (res.code === 0) {
            oss.getQuestionFileToken(file.name).then(data => {
              let fData = data.res
              const formData = new FormData()
              formData.append('key', fData.dir)
              formData.append('OSSAccessKeyId', fData.accessId)
              formData.append('policy', fData.policy)
              formData.append('Signature', fData.signature)
              formData.append('file', file)
              fetch(`https://${fData.host}`, {
                method: 'POST',
                body: formData
              }).then(() => {
                _this.url = `https://${fData.host}/${fData.dir}`
                _this.canAble = true
              })
            })
          }
        })
      }
      return false
    },
    uploadSerFile(file) {
      let form = {
        // answerPaperId: 0,
        examId: this.row.examId,
        // jobNumber: 0, // 工号
        reportType: 0, // 0 =》 试卷报告 1=》 个人报告
        reportUrl: file
        // userId: 0
      }
      examApi.importExam(form).then(res => {
        if (res.code === 0) {
          this.message('成功', 'success')
          this.IsImportExam = false
          this.searchData(true)
        }
      })
    },
    downloadExamReport(row) {
      let form = {
        examId: row.examId,
        reportType: 0 // 0=> 试卷报告 1=> 个人报告
      }
      examApi.getExamReport(form).then(res => {
        if (res.res === '') {
          return this.message('未导入报告或链接已失效', 'warning')
        }
        window.open(res.res)
      })
    },
    exportExamFile(row) {
      this.file = null
      this.row = row
      this.IsImportExam = true
      // console.log(row)
    },
    examReport(row) {
      if (row.type === 4) {
        this.$refs.StaffSelect.open(true, row.examId, '')
      } else {
        this.$router.push({
          name: 'examNewResult',
          params: {
            id: row.examId,
            examName: row.name,
            // tabLabel: this.$route.query.tabLabel,
            paperBankId: this.$route.query.paperBankId,
            paperId: this.$route.query.id,
            exam: true,
            name: this.$route.query.name,
            IsExamLibrary: true
          }
        })
      }
    },
    checkLength() {
      if (this.selectedExamId.length > 0) {
        this.deleteVisible = true
      } else {
        this.$message.warning('请选择要删除的试卷')
      }
    },
    handleRel() {
      this.showRel = true
      this.relForm.description = ''
      this.relForm.name = ''
    },
    confirmRel() {
      this.$refs['relForm'].validate(val => {
        if (val) {
          if (
            this.relForm.endTime !== '' &&
            this.relForm.startTime !== '' &&
            this.relForm.endTime !== null &&
            this.relForm.startTime !== null &&
            this.relForm.endTime <= this.relForm.startTime
          ) {
            return this.message('结束时间请不要' + (this.relForm.endTime < this.relForm.startTime ? '早于' : '等于') + '开始时间', 'warning')
          }
          this.isConfirm = true
          this.relForm.paperId = this.$route.query.id
          examApi
            .addExam(this.relForm, this.$route.query.id)
            .then(res => {
              this.$message.success('发布成功')
              this.searchData(this.page, this.size)
            })
            .finally(() => {
              this.showRel = false
              this.isConfirm = false
            })
        }
      })
    },
    cancelRel() {
      this.relForm.description = ''
      this.relForm.limitTime = 1
      this.relForm.name = ''
      this.showRel = false
    },
    examExaminer(row) {
      this.$router.push({
        path: '/exam/examiner',
        query: {
          tabLabel: this.$route.query.tabLabel,
          paperBankId: this.$route.query['paperBankId'],
          exam: true,
          name: row.name,
          examId: row.examId,
          id: this.paperId
        }
      })
    },
    selectedChange(val) {
      let row = []
      val.map(item => {
        row.push(item.examId)
      })
      this.selectedExamId = row
    },
    handleOperationSuccess(name, res) {
      // this.$emit(name, res)
    },
    initTableData(page, size) {
      this.loading = true
      exam
        .getPaperExams(page, size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchData(refresh = false) {
      const page = this.page
      const size = this.size
      let searchContent = this.searchContent
      let paperId = this.$route.query.id
      if (refresh) {
        // this.loading = true
        this.isSpread = false
      }
      exam.getPaperExams(paperId, page, size, searchContent).then(res => {
        this.total = res.res.total
        this.tableData = res.res.data
      })
    },
    changePage(val) {
      this.page = val - 1
      this.searchData()
    },
    changeSize(val) {
      this.size = val
      this.searchData()
    },
    getStatus(status) {
      return getStatusByValue(status)
    },
    handleClickMenu(name, row) {
      switch (name) {
        case 'delete':
          this.handleDeleteExam(row)
          break
        case 'preview':
          this.handlePreviewExam(row)
          break
        case 'remark':
          this.showAllocateModal = true
          break
        default:
          break
      }
    },
    handlePreviewExam(row) {
      this.$router.push(`/manage/exam/detail/${row.examId}`)
    },
    handleDeleteExam(row) {
      this.selectedExamId = [row.examId]
      this.deleteVisible = true
    },
    deleteExam() {
      this.deleteLoading = true
      examApi
        .delExam(this.selectedExamId)
        .then(() => {
          this.$message.success('删除成功')
          this.selectedExamId = []
          this.searchData()
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
        })
    },
    checkStartTime(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.relForm.startTime = ''
      }
    },
    checkStartDate(date) {
      return Date.now() > date.getTime() + 24 * 60 * 60 * 1000
    },
    checkEndDate(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.relForm.endTime = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.examlist-layout {
  height: 100%;
  .func-bar {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    position: relative;
    display: flex;

    .batchDele {
      width: 50%;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .filter-btn {
      margin-right: 20px;
      min-width: 100px;
    }

    .search-container {
      width: 50%;
      float: right;
      margin-top: 10px;
      text-align: right;
      margin-right: 42px;
      .search {
        width: 60px;
      }
      .searchInput {
        width: 280px;
      }
      .searchInput ::v-deep .ivu-input {
        border: 0;
        display: inline-block;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }

      .advanced-container {
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        font-size: 14px;
        padding-right: 20px;

        svg.icon {
          transition: all @default-transition;
          transform: rotate(0deg);
          margin-left: 8px;
        }

        svg.icon.spread {
          transform: rotate(180deg);
        }
      }

      .advanced-board {
        position: absolute;
        z-index: 100;
        top: 56px;
        right: 0;
        width: 390px;
        height: 0;
        overflow: hidden;
        border-right: solid 5px @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: all @default-transition;

        &.show {
          height: 250px;
        }

        .search {
          margin-right: 20px;
          height: 40px;
          border-radius: 4px;
        }

        .form {
          width: 330px;
          margin-top: 20px;
          ::v-deep .ivu-form-item-label {
            width: 60px !important;
          }
          ::v-deep .ivu-form-item-content {
            display: flex;
          }
          ::v-deep .ivu-input {
            width: 250px;
          }
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    ::v-deep .ivu-table {
      height: inherit;
      .opt-column {
        .operation {
          cursor: pointer;
          color: @primary-color;
          transition: all @default-transition;

          &:hover {
            color: @primary-hover-color;
          }
        }
      }
    }
    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .name-column {
        width: 20%;
      }
      .fileName-column {
        width: 30%;
      }
      .type-column {
        width: 15%;
      }
      .time-column {
        width: 15%;
        flex: 1 1 auto;
      }
      .ivu-table-body {
        cursor: pointer;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  ::v-deep .ivu-table-overflowX {
  }
  .row-action-container * + * {
    margin-left: 10px;
    i {
      font-size: 18px;
    }
  }
  ::v-deep .ivu-table-cell {
    white-space: inherit;
  }
}
.table-wrapper {
  padding: 0;
  ::v-deep .flex-table {
    width: 100%;
    .select-column {
      width: 35px;
    }
    .paper-name-column {
      width: 15%;
      flex: 1 1 auto;
    }
    .name-column {
      width: 10%;
    }
    .time-column {
      width: 9%;
    }
    .num-column {
      width: 7%;
    }
    .count-column {
      width: 8%;
    }
  }
}
.add-btn {
  margin-right: 15px;
}
.add-modal ::v-deep .ivu-modal {
  .footer {
    text-align: center;
    .modal-btn:first-child {
      margin-right: 10px;
    }

    .modal-btn {
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
}
.uploadDialog /deep/ .el-dialog {
  min-width: 520px;
}
</style>
