<template>
  <el-dialog :visible="visible" title="修改考试" width="500px" class="paper-modal" @close="close" center :close-on-click-modal="false">
    <Form ref="paperBankForm" :model="paperBankForm" :rules="ruleValidate" :label-width="80">
      <FormItem label="考试名称" prop="name">
        <Input v-model="paperBankForm.name" placeholder="请输入考试名称" />
      </FormItem>
      <FormItem label="考试简介" prop="description">
        <Input v-model="paperBankForm.description" placeholder="请输入考试简介" />
      </FormItem>
      <FormItem label="考试时长" prop="limitTime">
        <InputNumber v-model="paperBankForm.limitTime" placeholder="请输入考试时长(分钟)" :min="1" :max="999" style="width: 100%" />
      </FormItem>
      <FormItem label="通过比例" prop="passRate">
        <InputNumber v-model="paperBankForm.passRate" placeholder="请输入考试通过分数" :min="0" :max="1" :step="0.1" style="width: 100%" />
      </FormItem>
      <FormItem label="开始时间">
        <!--        <DatePicker type="datetime" v-model="paperBankForm.startTime" :options="startD" placeholder="请选择日期和时间" style="width: 200px" />-->
        <el-date-picker
          v-model="paperBankForm.startTime"
          type="datetime"
          size="small"
          style="width: 200px"
          :picker-options="{ disabledDate: checkStartDate }"
          @change="checkStartTime"
          placeholder="选择日期和时间"
        >
        </el-date-picker>
      </FormItem>
      <FormItem label="结束时间">
        <!--        <DatePicker type="datetime" v-model="paperBankForm.endTime" :options="startD" placeholder="请选择日期和时间" style="width: 200px" />-->
        <el-date-picker
          v-model="paperBankForm.endTime"
          type="datetime"
          size="small"
          style="width: 200px"
          :picker-options="{ disabledDate: checkStartDate }"
          @change="checkStartDate(paperBankForm.endTime)"
          @blur="checkStartDate(paperBankForm.endTime)"
          placeholder="选择日期和时间"
        >
        </el-date-picker>
      </FormItem>
    </Form>
    <div class="exam-footer">
      <Button @click="close" class="modal-btn" style="margin-right: 10px;">取消</Button>
      <Button :loading="loading" type="primary" class="modal-btn" @click="dealQuestionBank('paperBankForm')">确定</Button>
    </div>
  </el-dialog>
</template>
<script>
import postExam from '../../../../api/postExam'
import utilApi from '../../../../util/util'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    examId: {
      type: Number
    },
    paperBank: {
      type: Object
    }
  },
  data: function() {
    return {
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      percentage: 0,
      uploadUrl: '',
      uploadSuccess: false,
      isUploading: false,
      visible: this.value,
      loading: false,
      startD: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      paperBankForm: {
        name: this.paperBank.name,
        description: this.paperBank.description,
        limitTime: this.paperBank.limitTime,
        passRate: this.paperBank.condition,
        startTime: this.paperBank.startTime,
        endTime: this.paperBank.endTime
      },
      ruleValidate: {
        name: [
          { required: true, message: '请设置考试名称', trigger: 'blur' },
          { max: 30, message: '考试名名称不得超过30个字', trigger: 'blur' }
        ],
        description: {
          max: 30,
          message: '考试名简介不得超过30个字',
          trigger: 'blur'
        },
        limitTime: {
          type: 'number',
          required: true,
          message: '考试时长不为空',
          trigger: 'blur'
        },
        passRate: {
          type: 'number',
          required: true,
          message: '请设置通过比例',
          trigger: 'blur'
        }
      }
    }
  },
  inject: ['reload'],
  methods: {
    checkStartTime(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.paperBankForm.startTime = ''
      }
    },
    checkStartDate(date) {
      return Date.now() > date.getTime() + 24 * 60 * 60 * 1000
    },
    checkEndDate(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.paperBankForm.endTime = ''
      }
    },
    formDateTime(date) {
      let d = new Date(date)
      return utilApi.dateTime(d)
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.isUploading = false
      this.uploadSuccess = false
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    //判断是创建列表还是修改试列表
    dealQuestionBank(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if (
            this.paperBankForm.startTime !== '' &&
            this.paperBankForm.endTime !== '' &&
            this.paperBankForm.endTime !== null &&
            this.paperBankForm.startTime !== null &&
            this.paperBankForm.endTime <= this.paperBankForm.startTime
          )
            return this.message('结束时间请不要早于开始时间', 'warning')
          this.loading = true
          let paperBankInfo = Object.assign(this.paperBankForm)
          //修改需要多添加一项id
          paperBankInfo.examId = this.paperBank.examId
          postExam.editExam(paperBankInfo).then(() => {
            this.close()
            this.$message.success('修改成功')
            this.$emit('on-success')
            this.reload()
            this.loading = false
          })
          // .finally(() => {
          //   this.isUploading = false
          //   this.uploadSuccess = false
          // })
        } else {
          this.loading = false
        }
      })
      this.loading = false
    }
  },
  watch: {
    value(val) {
      this.visible = val
      // this.paperBankForm = {
      //   name: this.paperBank.name,
      //   description: this.paperBank.description,
      //   limitTime: this.paperBank.limitTime,
      //   passRate: 0.6
      // }
    },
    paperBank(val) {
      // val.startTime = this.formDateTime(val.startTime)
      // val.endTime = this.formDateTime(val.endTime)
      this.paperBankForm = {
        name: val.name,
        description: val.description,
        limitTime: val.limitTime,
        startTime: val.startTime,
        endTime: val.endTime,
        passRate: val.condition
      }
      // console.log(val)
    }
  }
}
</script>
<style lang="less" scoped>
.paper-modal ::v-deep .ivu-modal {
  .ivu-modal-body {
    .custom-upload {
      .ivu-upload-drag {
        width: 124px;

        img {
          width: 124px;
          height: 90px;
        }
      }

      &.uploaded {
        .ivu-upload-drag {
          border: none;
          display: inline;

          &:hover {
            border: none;
          }
        }
      }
    }

    .upload {
      width: 124px;
      height: 90px;
      font-size: 60px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #b8c2d1;
      }
    }
  }
}
.exam-footer {
  display: flex;
  justify-content: center;
}
.footer {
  text-align: center;
  .modal-btn:first-child {
    margin-right: 10px;
  }

  .modal-btn {
    .ivu-icon {
      font-size: 14px;
    }
  }
}
</style>
